@import "swiper/scss";
@import "swiper/scss/pagination";
@import "swiper/scss/navigation";
@import "swiper/scss/autoplay";

.antialiased{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loader{
  border-top-color: #363636;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner{
  0%{ -webkit-transform: rotate(0deg); }
  100%{ -webkit-transform: rotate(360deg); }
}

@keyframes spinner{
  0%{ transform: rotate(0deg); }
  100%{ transform: rotate(360deg); }
}

// Swiper
.swiper-pagination-bullet{
  @apply w-10
  h-10
  rounded-full
  border
  flex
  items-center
  justify-center
  transition-all
  duration-200
  ease-in-out
  active:bg-transparent
}

.swiper-pagination-bullet-active {
  @apply bg-transparent
  text-neutral-white
  border-neutral-white
}

.overflow-hidden{
  .scroll-to-top{
    display: none;
  }
}
